<template>
  <v-toolbar
    flat
    color="transparent"
    class="flex-grow-0 item-dialog-toolbar"
    :dark="dark"
    height="47"
  >
    <!-- <template
      v-if="type === 'preview'"
    >
      <v-menu
        v-model="verifiedMenu"
        :close-on-content-click="false"
        offset-y
        bottom
        right
        min-width="250"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="mr-6"
            v-on="on"
            color="#4be4b4"
            v-bind="attrs"
          >
            mdi-checkbox-marked
          </v-icon>
        </template>
        <v-card>
          <v-toolbar
            flat
            height="48"
            color="transparent"
          >
            Toolbar state
          </v-toolbar>
        </v-card>
      </v-menu>
      <v-tabs
        v-model="model"
      >
        <v-tab
          style="text-transform: none;;"
          v-for="tab in tabs"
          :key="`tab-${tab.id}`"
        >
          {{ tab.label }}
        </v-tab>
        <v-divider 
          vertical
        />
        <v-btn
          style="text-transform: none;"
          height="100%"
          text
          @click="$emit('changeCommentsState')"
        >
          Comments
        </v-btn>
      </v-tabs>
    </template> -->
    <template
      v-if="type === 'edit' || type === 'create' || type === 'delete'"
    >
      <v-icon
        size="18"
        class="mr-3"
      >
        mdi-layers-triple-outline
      </v-icon>
      <input
        v-if="type === 'edit' || type === 'create'"
        style="outline: none; color: inherit; font-size: 14px;"
        type="text"
        ref="input"
        placeholder="Untitled"
        v-model="item.info.title"
        @input="onInput"
      />
      <template v-else>
        {{ item.info.title || 'Untitled'}}
      </template>
    </template>
    <template v-else>
      <slot />
    </template>
    <v-spacer />
    <v-btn
      @click="$emit('clickOnClose')"
      icon
    >
      <v-icon>
        mdi-close
      </v-icon>
    </v-btn> 
  </v-toolbar>
</template>
<script>
export default {
  props: {
    dark: {
      type: Boolean
    },
    tabs: {
      type: Array,
      required: true
    },
    value: {
      required: true
    },
    type: {
      type: String,
      required: true,
      default: () => 'preview'
    },
    item: {
      required: true
    }
  },
  name: 'ItemDialogToolbar',
  data: () => ({
    inputWidth: null,
    verifiedMenu: false
  }),
  mounted() {
    this.type === 'edit' && setTimeout(this.onInput, 100)
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onInput() {
      const { input } = this.$refs

      input.setAttribute('size', input.value.length)
    }
  },
  watch: {
    type(to) {
      if (to === 'edit') {
        setTimeout(this.onInput, 100)
      }
    }
  }
}
</script>
<style lang="scss">
.item-dialog-toolbar {
  background: #fff;
}
.theme--dark .item-dialog-toolbar {
  background: #000;
}
</style>